.line-chart{
  padding: 20px;
}

@media screen and (max-width: 900px){
  .line-chart{
    width: 100%;
  }
}

@media screen and (min-width: 900px){
  .line-chart{
    width: 50%;
  }
}
