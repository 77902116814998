.summary {
  flex-wrap: wrap;

  .value-with-unit {
    width: 50%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.summary-group {
  width: 30%;
  min-width: 550px;
}

